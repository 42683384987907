/* eslint-disable no-param-reassign */

import { LocalUserChoices } from '@livekit/components-react';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IUserConfig } from '../../components';
import { RootState } from '../store';
import { streamGoApi } from '../streamGoApi';
import { KeyType } from './enums';

interface ILiveKitRoomProps {
  hq: boolean | undefined;
  roomName: string;
  userChoices: LocalUserChoices;
  userConfig: IUserConfig;
}

export interface IAuthState {
  isAltView: boolean;
  isAuthenticated: boolean;
  isModerator: boolean;
  liveKitRoomProps?: ILiveKitRoomProps;
  liveKitToken?: string;
  logout: boolean;
  showLoginPage: boolean;
  token?: string;
}

function handleApiAuthResponse(
  action: PayloadAction<string>,
  state: IAuthState,
) {
  const [key, data] = action.payload.split('=');

  if (key === KeyType.Token) {
    state.token = data;
    state.isAuthenticated = true;
  }
}

const initialState: IAuthState = {
  isAltView: false,
  isAuthenticated: false,
  isModerator: false,
  logout: false,
  showLoginPage: false,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => ({
      ...initialState,
      logout: true,
    }),
    addWebcamToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      state.isAuthenticated = true;
    },
    addLiveKitToken: (state, action: PayloadAction<string>) => {
      state.liveKitToken = action.payload;
    },
    setShowLoginPage: (state, action: PayloadAction<boolean>) => {
      state.showLoginPage = action.payload;
    },
    setIsModerator: (state, action: PayloadAction<boolean>) => {
      state.isModerator = action.payload;
    },
    setLiveKitRoomProps: (state, action: PayloadAction<ILiveKitRoomProps>) => {
      state.liveKitRoomProps = action.payload;
    },
    setIsAltView: (state, action: PayloadAction<boolean>) => {
      state.isAltView = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      action =>
        [
          streamGoApi.endpoints.login.matchFulfilled,
          streamGoApi.endpoints.check.matchFulfilled,
        ].some(matcher => matcher(action)),
      (state, action: PayloadAction<string>) => {
        handleApiAuthResponse(action, state);
        streamGoApi.util.invalidateTags(['user']);
      },
    );
  },
});

export const {
  addLiveKitToken,
  addWebcamToken,
  logout,
  setIsAltView,
  setIsModerator,
  setLiveKitRoomProps,
  setShowLoginPage,
} = slice.actions;
export default slice.reducer;

export const selectIsAuthenticated = (state: RootState) =>
  state.auth.isAuthenticated;

export const selectLiveKitToken = (state: RootState) => state.auth.liveKitToken;

export const selectIsLoggedOut = (state: RootState) => state.auth.logout;

export const selectShowLoginPage = (state: RootState) =>
  state.auth.showLoginPage;

export const selectIsModerator = (state: RootState) => state.auth.isModerator;

export const selectLiveKitRoomProps = (state: RootState) =>
  state.auth.liveKitRoomProps;

export const selectIsAltView = (state: RootState) => state.auth.isAltView;
